@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


.homeBusinessContent{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    height: 100%;
    padding: 25px;
    flex-wrap: wrap;
}
.trending-creators {
    @apply font-sans font-bold text-xl text-left text-primary-color my-3 ;
}
.campaign_info {
    @apply font-sans font-normal text-sm text-left text-black mb-4;
}
.topContent-banner{
    background-image: url("../../../component/assets/images/banner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:fit-content;
    width: 100%;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 10px 0px rgba(130, 126, 126, 0.75);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left:40px;
    padding-top:30px;
    padding-bottom:30px;
}

.topContent-banner h1{
    @apply font-sans font-bold text-5xl text-left text-white ;
}

.topContent-banner p{
    @apply text-lg font-semibold font-sans text-white text-left my-4;

}


.buttonWhite{
    @apply font-bold w-full text-sm text-white rounded-lg cursor-pointer py-4 px-2 me-4 bg-primary-color hover:bg-white hover:text-primary-color;
}

.buttonSilent{
    @apply font-bold w-full text-sm text-white rounded-lg cursor-pointer py-4 px-2 me-4 bg-white text-black hover:bg-primary-color hover:text-white;
}


.buttonOptions{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    gap: 10px;
    width: 90%;
    align-items: space-evenly;
  
}

.creatorListing{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
     padding: 10px;
     margin: auto;
    width:100%;
    background-color: white;
   margin-top: 10px;
    height: 100%; 
}

.trendingCreatorLists{
    @apply w-full flex flex-row text-lg font-medium text-center text-gray-900 border-b border-gray-200 dark:border-gray-700 dark:text-gray-900;
}

.tableHeaderCreator{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    background-color: white;
}

 .buttonThem{
    @apply font-bold text-sm text-white rounded-sm w-fit cursor-pointer py-2 px-3 me-4 bg-primary-color hover:bg-white hover:text-primary-color;
}
.saveBut{
    @apply w-full font-bold ml-auto my-3 self-end text-sm text-white rounded-sm  cursor-pointer py-2 px-3  bg-green-500;
}
.tableHeaderCreator h3{
   
    font-size: 14px;
    color: #2B3674;
}

.trandingCreators{
    @apply flex flex-row flex-wrap align-middle justify-start w-full gap-4 my-3 ;
}


.trendingCreatorsHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:90%;
    align-self: center;
    background-color: transparent;
}

.homeBusinessLeftSide{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
   position: relative;
    width:60%;
}

@media screen and (min-width: 481px) and (max-width: 767px){
    .trandingCreators{
        @apply flex-wrap h-fit
    }
    .buttonOptions{
        flex-direction: column;
        gap: 10px;
        width: 80%;
        margin: auto;
        justify-content: center;
        align-items: center;
    }
   
  }
  @media screen and (min-width: 768px) and (max-width: 999px){
    .trandingCreators{
        @apply flex-wrap h-fit
    }
    .buttonOptions{
        
        width: 75%;
       
    }
  }