@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@font-face {
  font-family: "UbuntuRegular";
  src: local("UbuntuRegular"),
   url("../../fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
  /* font-weight: 400 !important; */
  font-weight: normal !important;
 }
 @font-face {
  font-family: "Raleway";
  src: local("Raleway"),
   url("../../fonts/raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 1000 !important;
 }

 @font-face {
  font-family: "Raleway-eyu";
  src: local("Raleway-eyu"),
   url("../../fonts/raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 1000 !important;
 }


 @font-face {
  font-family: "Raleway-bolder-eyu"; 
  src: local("Raleway-bolder-eyu"),
   url("../../fonts/raleway/Raleway-Bold.ttf") format("truetype");
  font-weight: 1000 !important;
 }

 @font-face {
  font-family: "ubuntu-eyu"; 
  src: local("ubuntu-eyu"),
   url("../../fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
   font-weight: 0px !important;

 }

 @font-face {
  font-family: "ubuntu-eyu-light"; 
  src: local("ubuntu-eyu"),
   url("../../fonts/ubuntu/Ubuntu-Light.ttf") format("truetype");
   font-weight: 0px !important;

 }


 h1{
  font-family: "Raleway" !important;
 }
 p, button, h3, h4, h5{
  font-family: "ubuntu-eyu" !important;


 }


 .header-3{
  font-family: "ubuntu-eyu-light";
  font-size: 1.5em;
  width: 600px;
  font-weight: 0px !important;

}

 span{
  font-family: "Raleway-bolder-eyu";
 }
 .leftSideHero span {
  font-size: 1.2em;
}

  .top-header { 
  font-weight: 0;
  font-size: 0.7em !important;
  font-family: "Raleway-eyu";
 }


.header__title {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-top: 10px;
}

.navigationWrapper li {
  @apply font-bold;
}
.header__subtitle {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}
.test {
  @apply font-semibold;
}



.header {
  background: linear-gradient(
    141deg,
    rgba(185, 183, 198, 1) 0%,
    rgba(23, 30, 145, 1) 0%,
    rgba(13, 10, 10, 1) 54%,
    rgba(15, 25, 212, 1) 94%
  );
  /* background: conic-gradient(from -60deg at 168.68% 21.33%, black 71deg, #0D0D70 190deg, #171738 254deg); */
  margin-bottom: 10px;
  width: 100%;
  /* height: 100dvh; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
}
.Link-white {
  @apply font-semibold font-sans cursor-pointer text-lg hover:text-primary-color;
}
.Link-selected {
  @apply font-bold font-sans cursor-pointer text-lg text-primary-color;
}
.Link-black {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.header-white {
  background: white;
  /* background: conic-gradient(from -60deg at 168.68% 21.33%, black 71deg, #0D0D70 190deg, #171738 254deg); */
  margin-bottom: 10px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
}

.headerMenu ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding-top: 20px;
  flex-direction: row;
  color: white;
  align-self: flex-end;
  width: max-content;
}

.headerMenu {
  /* position:fixed;
  top:0;
  z-index:200; */
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: row;
  width: 73%;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: transparent;
  
}

.headerMenu ul li {
  @apply mx-3 cursor-pointer text-lg p-2 text-white w-fit;
}

.headerLogo {
  align-self: flex-start;
  margin: 0px;
}

.headerLogo img {
  margin: 0px;
  width: 100%;
  height: 100px;
}
.header_body_2 {
  background-color: black;
  position: relative;
  height: 40;
  width: 100%;
}

.headerHero {
  @apply flex flex-row w-9/12  h-fit justify-center align-middle items-center self-center;
}

.headerHero-2 h3{
  font-family: "Raleway" !important
}
.headerHero-2 {
  @apply flex flex-row justify-center items-center w-full;
}
.leftSideHero {
  /* align-items: flex-start;
  display: flex;
  width: 60dvh;
  flex-direction: column;
  height: fit-content;
  padding-bottom: 30px;
  position: relative;
  top: 50px;
  margin-right: 30px;
  border: 1px solid white; */
  @apply flex flex-col align-baseline items-start h-fit ms-8 w-2/5 pb-8;
}

.joinOptions {
  @apply flex flex-row w-full justify-between mt-5 ;
}

.joinOptions button {
  @apply font-bold text-lg text-white rounded-lg w-2/4 cursor-pointer p-5 me-4 bg-primary-color hover:bg-white hover:text-primary-color;
}

.bigScreenMenu {
  display: inline;

}

.smallScreenMenu {
  display: none;
}

.leftSideHero h1 {
  @apply font-bold font-sans text-white text-left;
}



/* Medium devices (tablets) */

.leftSideHero h2 {
  @apply font-bold font-sans text-white my-4 text-left;
}

.leftSideHero p {
  @apply text-lg font-sans text-white text-left my-4;
}

.rightSideHero {
  /* border:1px solid white; */
  @apply w-3/5 h-3/4 me-5;
}

.rightSideHero img {
  width: 100%;
  height: 100%;
}

.headerHero-2 h3 {
  @apply font-sans font-bold text-6xl my-20;
}
/* let's make the above mobile responsive */
@media (max-width: 768px) {
  .leftSideHero h1 {
    @apply text-5xl text-left; /* text-3xl on large screens (lg) */
  }
  .leftSideHero h2 {
    @apply text-4xl text-left; /* text-3xl on large screens (lg) */
  }
  .headerHero {
    @apply justify-center align-top items-start  w-full h-fit;
  }
  .leftSideHero {
    @apply flex flex-col align-middle h-fit ms-1 mt-8 w-full pb-8;
    /* border: 1px solid white; */
  }
  .joinOptions {
    @apply flex flex-col;
  }
  .joinOptions button {
    @apply w-full my-2 p-2;
  }
}

@media screen and (max-width: 768px) {
 
  .header-3{
    font-family: "ubuntu-eyu-light";
    font-size: 1.5em;
    width: 320px;
    font-weight: 0px !important;
  
  }

  .header__title {
    font-size: 3rem;
  }

  .bigScreenMenu {
    display: none;
  }
  .headerMenu {
    
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
    width: 90%;
  
  }
  .header__subtitle {
    font-size: 1.5rem;
  }
  .header {
    padding: 20px;
  }
  .smallScreenMenu {
    display: flex;
    flex-direction: column;

    padding: 0px;
    justify-content: center;
    align-items: flex-end;
  }

  .smallScreenMenu .menuButton {
    display: flex;
    flex-direction: column;
    border: none;
    box-shadow: 2px 2px 2px black;
    padding: 0px;
    justify-content: center;
    align-items: flex-end;
    background-color: transparent;
  }

  .rightSideHero {
    display: none;
  }
  .headerHero::after {
    display: none;
  }

  /* .leftSideHero h1 {
    margin: 0px;
    font-size: 12dvh;
    color: white;
  }

  .leftSideHero h2 {
    margin: 0px;
    color: white;
    font-size: 6dvh;
  }

  .leftSideHero p {
    color: white;
    text-align: start;
    width: 45dvh;
  } */

  .joinOptions button {
    padding: 1rem
  }

 
}
@media (min-width: 768px) {
  .leftSideHero h1 {
    @apply text-5xl; /* text-3xl on large screens (lg) */
  }
  .leftSideHero h2 {
    @apply text-4xl; /* text-3xl on large screens (lg) */
  }
  .leftSideHero {
    @apply w-4/6;
  }
  .headerHero {
    @apply mx-8 w-5/6 my-6;
  }

  .joinOptions button {
    @apply text-sm font-sans;
  }
}

@media (min-width: 768px) and (max-width: 1175px) {
  .joinOptions {
    @apply flex flex-col gap-5;
  }
  .joinOptions button {
    @apply w-[90%]  text-lg
  }
}

/* Large devices (desktops) */

/* Extra-large devices (large desktops, TVs) */

@media (min-width: 1024px) {
  .leftSideHero {
    @apply ms-20;
  }
  .leftSideHero h1 {
    @apply text-8xl; /* Larger font size for extra-large screens (xl) */
  }

  .headerHero {
    @apply mx-20 w-5/6 my-6;
  }

  .joinOptions button {
    @apply text-lg font-sans;
  }
}



@media screen and (min-width: 275px) and (max-width: 480px) {
  .headerHero-2 h3 {
    @apply font-sans font-bold text-2xl my-20;
  }
}

@media screen and (min-width: 481px) and (max-width: 600px) {
  .headerHero-2 h3 {
    @apply font-sans font-bold text-4xl my-20;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .headerHero-2 h3 {
    @apply font-sans font-bold text-4xl my-20;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .headerHero-2 h3 {
    @apply font-sans font-bold text-5xl my-20;
  }
  .headerLogo img{
    width: 100%;
    height:80px;
  }
}
