@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.mainDashboardScreen{
    width:100%;
    /* min-height: fit-content; */
    height: 100%;
    background-color: white;
    /* background-color: #F4F7FE; */
    position: absolute;
    padding:0px;
  
}
.toastContainer {
    @apply w-full bg-red-500 items-end align-middle justify-end
}
.iconButtons{
    margin:5px;
    /* margin-top: 10px; */
    color: #A3AED0;
    font-size: 24px;
}

.actionButton{
    @apply bg-green-600 rounded-sm text-sm text-white font-semibold p-3 w-28;
}

.statusButton-active{
    background-color: rgb(212, 210, 45);
    color: white;
    padding:10px;
    border:none;
    border-radius: 5px;
    width:100px;
}

.statusButton-rejected{
    background-color: rgb(212, 45, 45);
    color: white;
    padding:10px;
    border:none;
    border-radius: 5px;
    width:100px;
}
.statusButton-pending{
    @apply bg-gray-400 rounded-sm text-sm text-white font-semibold p-3 w-28;

}



.actionButton:hover{
    @apply bg-green-700 ;
}

.iconButtons-selected{
    margin:5px;
    margin-right: 0px;
    color: #090d25 ;
    font-size: 24px;

}

.leftNav ul{
    text-decoration: none;
    list-style: none;
    margin:5px;
    padding: 0px;;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.leftNav ul li{
   text-decoration: none;
   color:black;
    font-size: 39px;
    font-weight: 500;
    padding: 10px;
    margin: 0px;
    position: relative;
    left:0px;
    width: 100%;
    height: 46px;
}

.leftNav ul li p{
    margin: 9px;
}

.navButton{
    color:black;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    width:100%;
    /* padding:10px; */
    justify-content: flex-start;
}

li{
    list-style: none;
    margin:0px;
    padding:0px;
    font-size: 14px;
    color: #A3AED0;
    width:80%;
    background-color: transparent;
}
.navButton-selected{
    color:black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-decoration: none;
    /* background-color: #0e183b; */
    background-color: #D4D4D4;
    color: #0e183b;
    width:100%;
    padding: 1px;
    border-radius: 8px;;
    /* border-bottom-right-radius: 0px;
    border-top-right-radius: 0px; */
    /* padding:10px; */
    /* box-shadow: 0 0 6px 2px rgba(0.1,0.1,0.1,.1); */
    /* box-shadow: 2px 4px 5px black; */
}

.navButton p{
    /* margin:5px; */
    padding:0px;
    font-size: 14px;
    color: #A3AED0;
}


.navButton-selected p{
    /* margin:5px; */
    padding:0px;
    font-size: 12px;
    color: #0e183b;
}

*{
    text-decoration: none;
}

.navButton .cardIcon{
    color:red;
}


.cardIcon{
    font-size: 20px;;
    border-radius: 25px;;
    width:48px;;
    height: 48px;;
    margin:5px;
    overflow: hidden;
    background-color: #F4F7FE;
    color:#04020e;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:10px;
}

.innerSingleContent{
    display:flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
    font-family: sans-serif;
}

.innerSingleContent  h3{
    margin:0px;
    padding:0px;
    color: #2B3674;
    font-size: 28px;
    font-weight: 'bold';
}

.leftNav h3{
    color: white;
}

.leftNav h2{
    color: white;
}


.singleGraph-wide{
    width:92%;
    
    background-color: white;
    /* border-radius: 20px; */
    margin:5px;
    overflow: hidden;
    /* overflow-x: scroll !important; */
    margin-bottom: 40px;
}

.leftNav{
    height: 100%;
    width:13.5%;
    margin:0px;
    padding:0px;
    position:fixed;
    box-shadow: 2px 10px 30px 0 rgba(0,0,0,.42),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(0,0,0,.2);
    background-color: #090d25;
    overflow-y: scroll;
    z-index: 10;
}

.headerRight{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height:50px;
    margin:13px;
    margin-right:40px;
    width:250px;
    border-radius: 25px;
    background: white;

}

.homeMain{
    width: 100%;
    height:100%;
    display: flex;
    
    padding-bottom: 40px;
    overflow: scroll;
    flex-direction: column;
}

.topContent{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background: transparent;
    
}

.singleCardContent{
    /* border-radius: 20px; */
    background-color: red;
    margin:5px;
     width:32.9dvh;
    height: 70px; 
    overflow: hidden;
    display: flex;
    flex-direction: row;
   
}

.singleCardContent p{
    margin:0px;
    padding:0px;
    color: #A3AED0;
    font-size: 13px;;
    font-family: sans;
}

.singleCardContent h3{
    margin:0px;
    padding:0px;
    font-size: 20px;    ;
}

.headerTool{
    width:85%;
    height:90px;;
    background-color: transparent;
   padding:0px;
   position: inherit;
   right:0px;
    margin: 0px;;
    color:black;
    align-self: flex-end;
    justify-content: space-between;
    display:flex;
    flex-direction: row;
    border-bottom: 0.2px black solid !important;
    /* background: linear-gradient(180deg, #3874f6 0%, #7eabf3 35%, #ffffff 100%); */
}   


.dashboardContent{
    width:87%;
    /* height: fit-content; */
    overflow-y: scroll !important;
    position:absolute;
    right:0px;
    top:80px;
    /* background: #F5F6FB; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
}

.graphContent{
    width:93.6%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 10px;
    margin-top:10px;
    align-self: center;
min-height: 800px;
}


.singleGraph{
    width:99%;
    height:auto;
    background-color: white;
    /* border-radius: 20px; */
    margin:5px;
    overflow: hidden;
}

.headerLeft{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin:7px;
    width: 300px;
    /* background-color: rgb(13, 12, 53); */
    border-radius: 5px;
    padding: 10px;

}



.headerTool  p{
    margin:0px;
    padding:0px;
    text-align: start;
    color: #707EAE;
    font-size: 13px;
}
.headerLeft p, h2 {
    font-size: 20px;    
    font-weight: bold;
    color: #2B3674;
}

.headerTool  h2{
    margin:0px;
    padding:0px;
}


.mobileFooter{
    display: none;
}

.headerTool img{
    width:40px;;
    height:40px;;
    margin:5px;
    padding:0px;
    border-radius: 25px;
    cursor: pointer;
}

.navFooter{
    margin: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 200px;
}

.navFooter p{
    color: white;
     font-family: fantasy;
    width:100%;
     padding:10;
    margin:5;
     text-align:'center';
      bottom: -90;
     position: "relative"}

.headerTool h2{
    color: #2B3674;
}


@media screen and (min-width: 320px) and (max-width: 480px) {
    .mainDashboardScreen{
    height: 100%;
    overflow-y: scroll;
  
    }

  }

@media screen and (min-width: 320px) and (max-width: 480px) {
   .singleGraph{
         /* width:43dvh; */
         
         height:fit-content;
         background-color: white;
        overflow-x: scroll;
         margin:5px;
         padding-bottom: 20px;
         padding-left: 5px;

     }
     .singleCardContent{
        
         background-color: white;
         margin:1px;
         width:98%;
         height: 100px;
         justify-content: flex-start;
         overflow: hidden;
        box-shadow:0 0 6px 2px rgba(0,0,0,.1);;
     }


}

 
 @media screen and (min-width: 481px) and (max-width: 580px) {
    .singleCardContent{
         
        background-color: white;
        margin:5px;
        width:80%;
        height: 100px;
        /* justify-content: flex-start; */
        overflow: hidden;
       box-shadow:0 0 6px 2px rgba(0,0,0,.1);;
    }
    .singleGraph{
   
        
        height:fit-content;
        background-color: white;
       overflow-x: scroll;
        margin:5px;
        padding-bottom: 20px;

    }
 }
 @media screen and (min-width: 581px) and (max-width: 767px) {
    .singleGraph{
      
        
        height:fit-content;
        background-color: white;
       overflow-x: scroll;
        margin:5px;
        padding-bottom: 20px;
    }
    .singleCardContent{
         
        background-color: white;
        margin:5px;
        width:44%;
        height: 100px;
        justify-content: flex-start;
        overflow: hidden;
       box-shadow:0 0 6px 2px rgba(0,0,0,.1);;
    }
 }


 @media screen and (min-width: 768px) and (max-width: 999px) {
    .singleGraph{
          /* width:43dvh; */
          width: 96%;
          height:fit-content;
          background-color: white;
         overflow-x: scroll;
          margin:5px;
          padding-bottom: 20px;
 
      }
      .singleCardContent{
         
        background-color: white;
        margin:5px;
        /* width:30%; */
        height: 100px;
        justify-content: flex-start;
        overflow: hidden;
       box-shadow:0 0 6px 2px rgba(0,0,0,.1);;
    }
     
 }

 @media screen and (min-width: 1000px) and (max-width: 1200px) {
    .singleGraph{
          /* width:43dvh; */
          width: 93%;
          height:fit-content;
          background-color: white;
         overflow-x: scroll;
          margin:5px;
          padding-bottom: 20px;
 
      }
      .singleCardContent{
         
        background-color: white;
        margin:5px;
        /* width:30%; */
        height: 100px;
        justify-content: flex-start;
        overflow: hidden;
       box-shadow:0 0 6px 2px rgba(0,0,0,.1);;
    }
     
 }

 @media screen and (min-width: 1201px) and (max-width: 1440px) {
    .singleGraph{
          /* width:43dvh; */
          width: 46%;
          height:fit-content;
          background-color: white;
         overflow-x: scroll;
          margin:5px;
          padding-bottom: 20px;
 
      }
      .singleCardContent{
         
        background-color: white;
        margin:5px;
        /* width:30%; */
        height: 100px;
        justify-content: center;
        overflow: hidden;
       box-shadow:0 0 6px 2px rgba(0,0,0,.1);;
    }
     
 }

 @media screen and (min-width: 1441px) {
    .singleGraph{
          /* width:43dvh; */
          width: 47%;
          height:fit-content;
          background-color: white;
         overflow-x: scroll;
          margin:5px;
          padding-bottom: 20px;
 
      }
      .singleCardContent{
         
        background-color: white;
        margin-right:27px;
        margin-left: 0px;
        width:27.7vh;
        height: 100px;
        border-radius: 3px;
        justify-content: center;
        overflow: hidden;
       box-shadow:0 0 6px 2px rgba(0,0,0,.1);;
    }
     
 }
 
  @media screen and (min-width: 768px) and (max-width: 999px) {
    .leftNav{
        width:17% !important;
    }
    .dashboardContent{
        width:80% !important;
    }   
    .headerTool{
        width:80% !important;
        
    }   
  }

  @media screen and (min-width: 768px) {
    .mainDashboardScreen{
    height: fit-content;
    }
  }


