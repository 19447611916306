.campaign-form-overlay {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.campaign-form-overlay.open {
  right: 0;
}

.campaign-form-container {
  width: 400px;
  height: 100%;
  background-color: white;
  padding: 2rem;
  position: relative;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.form-group input[type="file"],
.form-group input[type="number"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.campaign-type-buttons {
  display: flex;
  gap: 1rem;
}

.type-button {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.type-button.active {
  background-color: #0088cc;
  color: white;
  border-color: #0088cc;
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #0088cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #006699;
}